import {MenuItemText} from "../../../../Texts";
import theme from "../../../../Theme";

import {HashLink} from "react-router-hash-link";

const ScrollMenuItem = ({path, text, selectedPage, onSelectedPageChanged}) => {

    const handleClick = () => {
        onSelectedPageChanged(path)
    }

    let color = theme.colors.napa
    if (selectedPage === path) {
        color = theme.colors.black;
    }

    return (
        <HashLink smooth to={path} style={{ textDecoration: 'none' }} onClick={handleClick}>
            <MenuItemText color={color}>{text}</MenuItemText>
        </HashLink>
    )
}

export default ScrollMenuItem;