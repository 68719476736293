import styled from 'styled-components';
import { ThemeProvider } from "styled-components";
import theme from "./Theme";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Home from "./pages/home/Home"
import React, {useState} from "react";
import {AuthProvider} from "../contexts/AuthContext";
import Payment from "./pages/payment/Payment";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./pages/dashboard/Dashboard";
import UpdateProfile from "./pages/profile/UpdateProfile";
import Login from "./pages/login/Login";
import Signup from "./pages/login/Signup";
import ForgotPassword from "./pages/login/ForgotPassword";
import MediaQuery from "react-responsive";
import DesktopMenuBar from "./pages/home/components/desktop/DesktopMenuBar";
import {CenterAlignedRow, YSpacer} from "./StyledComponents";
import {SubHeading} from "./Texts";
import MobileMenuBar from "./pages/home/components/mobile/MobileMenuBar";
import LocalizedStrings from "react-localization";
import {data} from "../assets/strings";

let strings = new LocalizedStrings(data);
strings.setLanguage('en');


const PageWrapper = styled.div`
  width: auto; 
  //overflow: hidden;
  //height: 100%;
`

const App = () => {



    return (
      <PageWrapper>
          <ThemeProvider theme={theme}>
              <Router>
                  <AuthProvider>

                      <MediaQuery minWidth={theme.widthQueries.minWidthQuery}>
                          <DesktopMenuBar />
                      </MediaQuery>
                      <MediaQuery maxWidth={theme.widthQueries.maxWidthQuery}>
                          <YSpacer backgroundColor={theme.colors.grey} spaceY={'7vw'} />
                          <MobileMenuBar />
                      </MediaQuery>

                      <Switch>
                          <Route exact path="/" component={Home} />
                          {/*<PrivateRoute path="/dashboard" component={Dashboard} />*/}
                          {/*<PrivateRoute path="/update-profile" component={UpdateProfile} />*/}
                          {/*<Route path="/login" component={Login} />*/}
                          {/*<Route path="/signup" component={Signup} />*/}
                          {/*<Route path="/forgot-password" component={ForgotPassword} />*/}
                          {/*<Route path="/payment" component={Payment} />*/}
                      </Switch>
                  </AuthProvider>
              </Router>
          </ThemeProvider>
      </PageWrapper>
    );
}

export default App;
