import React from 'react'
import DesktopMenuBar from "./components/desktop/DesktopMenuBar"
import EvolutionBand from "./components/generic/EvolutionBand"
import ScreenViews from "../../../assets/STRIDE screen views 2.png"
import styled from "styled-components";
import {
    XSpacer,
    CenterAlignedRow,
    Box,
    YSpacer, CenteredCol
} from "../../StyledComponents";
import LabeledCheckMark from "./components/generic/LabeledCheckMark";

import LocalizedStrings from 'react-localization'
import {data} from '../../../assets/strings.js'
import theme from "../../Theme";
import DemoButton from "./components/generic/DemoButton";
import {BodyText, BoldedBodyText, FixedWidthBodyText, SubHeading} from "../../Texts";
import {OrangeBand} from "./components/generic/OrangeBand";
import MediaQuery from "react-responsive";
import MobileMenuBar from "./components/mobile/MobileMenuBar";
import DesktopProblemSolution from "./components/desktop/DesktopProblemSolution";
import MobileProblemSolution from "./components/mobile/MobileProblemSolution";
import DesktopSignUpContact from "./components/desktop/DesktopSignUpContact";
import MobileSignUpContact from "./components/mobile/MobileSignUpContact";

let strings = new LocalizedStrings(data);
strings.setLanguage('en');

export const Screens = styled.img`
    position: relative;
    width: 100%;
    min-width: 500px;
    max-width: 1440px;
    height: auto;
`
Screens.defaultProps = {
    src: ScreenViews,
};


const Home = () => {



    return (
        <>
            <Box backgroundColor={'#C2BEB34D'} >

                <MediaQuery minWidth={theme.widthQueries.minWidthQuery}>
                    {/*<YSpacer spaceY={'2vw'} />*/}
                    {/*<DesktopMenuBar />*/}
                    <CenterAlignedRow>
                        <SubHeading>{strings.shortDescription}</SubHeading>
                    </CenterAlignedRow>
                </MediaQuery>
                <MediaQuery maxWidth={theme.widthQueries.maxWidthQuery}>
                    {/*<YSpacer spaceY={'7vw'} />*/}
                    {/*<MobileMenuBar />*/}
                    <CenterAlignedRow fontSize={'10px'}>
                        <SubHeading>{strings.shortDescription}</SubHeading>
                    </CenterAlignedRow>
                </MediaQuery>




                <CenteredCol position={'relative'}>
                    <MediaQuery minWidth={theme.widthQueries.minWidthQuery}>
                        <BodyText width={theme.fixedWidths.desktop} color={'#000000'}>{strings.whatWeDo}</BodyText>
                        <YSpacer spaceY={'2.3438rem'} maxHeight={'37.5px'} />
                        <DemoButton />
                        <BoldedBodyText width={'36vw'} color={'#000000'}>{strings.areYouProvider}</BoldedBodyText>
                    </MediaQuery>
                    <MediaQuery maxWidth={theme.widthQueries.maxWidthQuery}>
                        <BodyText width={theme.fixedWidths.mobile} color={'#000000'}>{strings.whatWeDo}</BodyText>
                        <YSpacer spaceY={'7vw'} />
                        <DemoButton />
                        <BoldedBodyText width={'70vw'} color={'#000000'}>{strings.areYouProvider}</BoldedBodyText>
                    </MediaQuery>
                    <Screens />
                </CenteredCol>
            </Box>



            <Box backgroundColor={'#FFFFFFFF'}>
                <YSpacer spaceY={'5vw'} max={'46px'}/>
                <CenterAlignedRow>
                    <XSpacer spaceX={'7vw'} />
                    <LabeledCheckMark text={strings.exerciseLibrary}/>
                    <XSpacer spaceX={'7vw'} />
                    <LabeledCheckMark text={strings.customPlan}/>
                    <XSpacer spaceX={'7vw'} />
                    <LabeledCheckMark text={strings.digitalFormat}/>
                    <XSpacer spaceX={'7vw'} />
                    <LabeledCheckMark text={strings.connectWithPT}/>
                    <XSpacer spaceX={'7vw'} />
                </CenterAlignedRow>
                <YSpacer spaceY={'7vw'} max={'64.4px'}/>
            </Box>

            <MediaQuery minWidth={theme.widthQueries.minWidthQuery}>
                <DesktopProblemSolution />
            </MediaQuery>
            <MediaQuery maxWidth={theme.widthQueries.maxWidthQuery}>
                <MobileProblemSolution />
            </MediaQuery>

            <EvolutionBand />

            <MediaQuery minWidth={theme.widthQueries.minWidthQuery}>
                <DesktopSignUpContact />
            </MediaQuery>
            <MediaQuery maxWidth={theme.widthQueries.maxWidthQuery}>
                <MobileSignUpContact />
            </MediaQuery>

            <OrangeBand minHeight={'125px'} height={'20vw'} maxHeight={'326px'}/>
        </>
    )
}


export default Home