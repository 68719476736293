
const data = {
  en: {
    about: "About",
    contactUs: "Contact Us",
    demo: "Demo",
    shortDescription: "Physical Therapy Companion App",
    whatWeDo: "Stride helps physical therapy patients recover quickly and easily, with treatment software that connects patients with their physical therapist.",
    areYouProvider: "Are you a provider? Click here",
    exerciseLibrary: "Exercise Library",
    customPlan: "Custom Plan",
    digitalFormat: "Digital Format",
    connectWithPT: "Connect with PT",
    problemTitle: "Problem",
    problemBulletList: ["Papers get lost", "No accountability", "Poor patient outcomes",],
    solutionTitle: "Solution",
    solutionBulletList: ["Digitized home exercise plan", "Send notifications to your patients phone", "Improve adherence up to 50%",],
    problemParagraph: "Physical therapy patients are not completing exercises prescribed by their physical therapist which is halting their progress towards recovery. Most patients are given a stack of papers with printed exercises to do at home and end up losing their packet or forgetting to do them.",
    solutionParagraph: "Stride offers a digital format for physical therapists to prescribe exercises and for patients to access them. Our aim is to make exercises easy to do and be tracked from anywhere with automated reminders and simplified video and written instructions.",
    signUpNow: "Schedule a Demo",
    signUpDescription: "Want to learn more about Stride? Sign up below to get a demo of the app.",
    demoButtonLabel: "GET DEMO",
    contactUsDescription: "Not quite ready to create a provider account or have another concern? Reach out. We would love to chat!",
    email: "Email",
    phone: "Phone",
    strideEmail: "support@takeastride.health",
    stridePhoneNumber: "+1 (385) 421-8184"
  }
}

export {data};
