import {Box, CenterAlignedCol, CenterAlignedRow, CenteredCol, XSpacer, YSpacer} from "../../../../StyledComponents";
import {BodyText, FixedWidthBodyText, MediumHeading} from "../../../../Texts";
import LocalizedStrings from "react-localization";
import {data} from "../../../../../assets/strings";
import BulletList from "../generic/BulletList";

let strings = new LocalizedStrings(data);
strings.setLanguage('en');

const MobileProblemSolution = () => {
    return (
        <Box backgroundColor={'#000000'}>
            <YSpacer spaceY={'7vw'} />
            <CenteredCol justifyContent={'center'}>

                <CenteredCol width={'100vw'} lineHeight={'20px'}>
                    <MediumHeading color={'#ffffff'}>{strings.problemTitle}</MediumHeading>
                    <BodyText color={'#ffffff'}>
                        {"Forgotten Exercise Papers\n" +
                            "Lack of Accountability\n" +
                            "Poor Patient Outcomes"}
                    </BodyText>
                    {/*<BulletList paddingLeft={'10vw'} width={'100vw'} items={strings.problemBulletList} textColor={'#ffffff'} />*/}
                </CenteredCol>

                <YSpacer spaceY={'7vw'} />

                <CenteredCol width={'100vw'} lineHeight={'20px'}>
                    <MediumHeading color={'#ffffff'}>{strings.solutionTitle}</MediumHeading>
                    <BodyText color={'#ffffff'}>
                        {"Digital Home Exercise Plan\n" +
                            "Notifications  for Patients\n" +
                            "Improve Adherence by 50%"}
                    </BodyText>
                    {/*<BulletList paddingLeft={'10vw'} width={'100vw'} listPosition={'relative'} listLeft={'25vw'} items={strings.solutionBulletList} textColor={'#ffffff'} />*/}
                </CenteredCol>

            </CenteredCol>
            <YSpacer spaceY={'10vw'} />
        </Box>
    )
}

export default MobileProblemSolution;