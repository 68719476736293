import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';


const stripePromise = loadStripe('pk_test_51KKRHdAaLU49gWAulGPLsmwE9S3th646lvRhpz5ByQZa8rNe8d5a8zMSPMFLdMgt7x0HoAc3oWrfuPbjip3ThFyH00ZC9ZrEV1');

ReactDOM.render(
    <React.StrictMode>
        <Elements stripe={stripePromise}>
            <App/>
        </Elements>
    </React.StrictMode>,
    document.getElementById('root')
);



