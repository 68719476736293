import styled from "styled-components";
import {CenterAlignedRow} from "../../../../StyledComponents";
import PaperAndIPhones from "../../../../../assets/combined_with_corner.png";

const Combined = styled.img`
  position: relative;
  z-index: -1;
  left: -3%;
  width: 100vw;
  max-width: 1519px;
  height: 31vw;
  max-height: 466px;
`
Combined.defaultProps = {
    src: PaperAndIPhones
}

const EvolutionBand = () => {
    return (
        <CenterAlignedRow backgroundColor={'#C2BEB34D'}
                     position={'relative'}
                     height={'31vw'}
                     maxHeight={'466px'}
                     zIndex={'-2'}>
            <Combined />
        </CenterAlignedRow>
    )
}

export default EvolutionBand;