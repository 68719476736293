import styled from 'styled-components'
import Line from '../../../../../assets/Rectangle 203.png'
import {Col, YSpacer} from "../../../../StyledComponents";

export const Container = styled.div`
  cursor: pointer;
`

const HamburgerMenu = () => {
    return (
        <Container>
            <Col>
                <img src={Line} />
                <YSpacer spaceY={2} />
                <img src={Line} />
                <YSpacer spaceY={2} />
                <img src={Line} />
            </Col>
        </Container>
    )

}

export default HamburgerMenu