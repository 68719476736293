import styled from "styled-components";
import OrangeBackground from "../../../../../assets/orange_gradient.png";

export const OrangeBand = styled.img`
  height: ${props => props.height};
  max-height: ${props => props.maxHeight};
  min-height: ${props => props.minHeight};
  margin-bottom: -5px;
  width: 100%;
`
OrangeBand.defaultProps = {
    src: OrangeBackground,
};
