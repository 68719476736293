import styled from "styled-components";
import {
    space,
    color,
    typography,
    position,
    border,
    background,
    layout,
    flexbox,
    grid,
    compose,
    system,
} from "styled-system";

export const BorderBoxDiv = styled.div`
    box-sizing: border-box;
`;

export const all = compose(
    space,
    color,
    typography,
    position,
    border,
    background,
    layout,
    flexbox,
    grid,
    system({
        cursor: true,
        writingMode: true,
        textOrientation: true,
        whiteSpace: true,
        transition: true,
        transform: true,
        boxShadow: true,
        backgroundAttachment: true,
        boxSizing: true,
        uppercase: {
            property: "textTransform",
            transform: () => "uppercase",
        },
    })
);


export const Box = styled(BorderBoxDiv)(all);

export const Background = ({ color, ...props }) => <Box backgroundColor={color} {...props} />;

export const YSpacer = ({ spaceY, max, ...props }) => <Box height={spaceY} maxHeight={max} {...props} />;
export const XSpacer = ({ spaceX, max, ...props }) => <Box width={spaceX} maxWidth={max} {...props} />;

const Flex = ({ ...props }) => <Box display={"flex"} {...props} />;

export const Row = ({ ...props }) => <Flex flexDirection={"row"} {...props} />;

export const Grid = ({ ...props }) => <Box display={"grid"} {...props} />;
export const Col = ({ ...props }) => <Flex flexDirection={"column"} {...props} />;

export const CenteredRow = ({ ...props }) => <Row alignItems={"center"} {...props} />;
export const CenteredCol = ({ ...props }) => <Col alignItems={"center"} {...props} />;

export const CenterAlignedRow = ({ ...props }) => <Row justifyContent={"center"} {...props} />;
export const CenterAlignedCol = ({ ...props }) => <Col justifyContent={"center"} {...props} />;


export const Center = ({ ...props }) => <CenteredCol justifyContent={"center"} {...props} />;


