import {Box, CenterAlignedRow, CenteredCol, Col, Row, XSpacer, YSpacer} from "../../../../StyledComponents";
import {BodyText, BoldedBodyText, FixedWidthBodyText, MediumHeading} from "../../../../Texts";
import DemoButton from "../generic/DemoButton";
import LocalizedStrings from "react-localization";
import {data} from "../../../../../assets/strings";
import theme from "../../../../Theme";

let strings = new LocalizedStrings(data);
strings.setLanguage('en');

const DesktopSignUpContact = (props) => {

    return (
        <Box backgroundColor={'#FFFFFFFF'}>
            <YSpacer spaceY={'5vw'} />
            <CenterAlignedRow>
                <CenteredCol id={'demo'}>
                    <MediumHeading color={'#000000'}>{strings.signUpNow}</MediumHeading>
                    <BodyText width={theme.fixedWidths.desktop}>{strings.signUpDescription}</BodyText>
                    <DemoButton />
                </CenteredCol>
                <XSpacer spaceX={'10vw'} />
                <CenteredCol id={'contact-us'}>
                    <MediumHeading color={'#000000'}>{strings.contactUs}</MediumHeading>
                    <BodyText width={theme.fixedWidths.desktop}>{strings.contactUsDescription}</BodyText>
                    <Row>
                        <Col>
                            <BoldedBodyText>{strings.email}</BoldedBodyText>
                            <BoldedBodyText>{strings.phone}</BoldedBodyText>
                        </Col>
                        <XSpacer spaceX={'1vw'} />
                        <Col>
                            <BoldedBodyText>{strings.strideEmail}</BoldedBodyText>
                            <BoldedBodyText>{strings.stridePhoneNumber}</BoldedBodyText>
                        </Col>
                    </Row>
                </CenteredCol>
            </CenterAlignedRow>
            <YSpacer spaceY={'5vw'} />
        </Box>
    )

}

export default DesktopSignUpContact;
