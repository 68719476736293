import styled from "styled-components";
import theme from "./Theme";

export const Alert = styled.div`
  width: calc(100% - 2.5rem);
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;

  border-radius: 0.25rem;
  
  ${props => props.variant === 'danger' && `
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
  `}

  ${props => props.variant === 'success' && `
    color: #155724;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
  `}


  font-family: Cooper Hewitt, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.fontSizes.small};
  color: ${props => props.color};
  text-align: center;
  white-space: pre-line;
`

export const StyledForm = styled.form`
      width: 100%;
`

export const FormLabel = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;

  font-family: Cooper Hewitt, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.fontSizes.small};
  text-align: center;
  white-space: pre-line;
`

export const FormInput = styled.input`
    display: block;
    width: calc(100% - 1.5rem);
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: ${props => props.color};
    background-clip: padding-box;
    border: 1px solid ${theme.colors.border_light_grey};
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    font-family: Cooper Hewitt, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: ${theme.fontSizes.small};
    text-align: left;
    white-space: pre-line;
`