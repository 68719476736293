import styled from "styled-components";
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {OrangeButton} from "../../../OrangeButton";
import {MediumHeading} from "../../../Texts";
import {Box, CenteredCol, YSpacer} from "../../../StyledComponents";
import theme from "../../../Theme";

const CardContainer = styled.div`
    width: 70vw;
    max-width: 20em;
    padding: 0px 10px 20px 10px;

    font-family: Cooper Hewitt, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: ${theme.fontSizes.small};
    color: #000000;
`

const CardForm = () => {

    const elements = useElements();
    const stripe = useStripe();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const {clientSecret} = await fetch('/create-payment-intent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                paymentMethodType: 'card',
                currency: 'usd'
            }),
        }).then(r => r.json());


        const {paymentIntent} = await stripe.confirmCardPayment(
            clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                }
            }
        )
    }

    return (
        <Box width={'90vw'}
             maxWidth={'30em'}
             height={'56.75vw'}
             maxHeight={'18.92em'}
             boxShadow={'10px 10px 5px grey'} backgroundColor={'#ffffff'}
             borderRadius={'12px'}>

            <form onSubmit={handleSubmit}>
                <CenteredCol>
                    <MediumHeading>Card</MediumHeading>
                    <CardContainer>
                        <CardElement />
                    </CardContainer>
                    <YSpacer height={'2em'} />
                    <OrangeButton>PAY $100</OrangeButton>
                </CenteredCol>
            </form>
        </Box>
    );
};

export default CardForm;

