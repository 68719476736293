import styled from 'styled-components'
import {CenterAlignedCol, CenterAlignedRow} from "../../../../StyledComponents";
import CheckMarkImg from "../../../../../assets/checkmark.png";
import theme from "../../../../Theme";

const CheckMark = styled.img`
  width: 5.2rem;
  min-width: 50px;
  height: auto;
`
CheckMark.defaultProps = {
    src: CheckMarkImg,
}

export const Text = styled.p`
  font-family: Cooper Hewitt, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: clamp(${theme.fontSizes.mobileSmall},
  ${theme.fontSizes.relativeSmall},
  ${theme.fontSizes.desktopSmall});
  color: ${props => props.color};
  text-align: center;
`;

export const Container = styled.div`
  
`

const LabeledCheckMark = (props) => {
    return (
        <Container>
            <CenterAlignedCol alignItems={'center'}>
                <Text>{props.text}</Text>
                <CheckMark />
            </CenterAlignedCol>
        </Container>
    )
}

export default LabeledCheckMark;