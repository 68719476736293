import { openPopupWidget } from "react-calendly";
import LocalizedStrings from "react-localization";
import {data} from "../../../../../assets/strings";
import {OrangeButton} from "../../../../OrangeButton";

let url = "https://calendly.com/takeastride/schedule-app-demo";

let utm = {}

let pageSettings = {
    backgroundColor: 'EDECE8',
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: 'FF3E00',
    textColor: '000000'
}

let prefill={}

let strings = new LocalizedStrings(data);
strings.setLanguage('en');

const DemoButton = () => {
  const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });

  return <OrangeButton onClick={onClick}>{strings.demoButtonLabel}</OrangeButton>;
};

export default DemoButton;



