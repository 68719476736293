import styled from "styled-components";
import theme from "../../../../Theme";
import StrideS from "../../../../../assets/Asset 5@500x 4.png";
import {Heading} from "../../../../Texts";

export const Logo = styled.img`
    width: ${theme.fontSizes.xl};
    height: auto;
    padding: 2px;
`;
Logo.defaultProps = {
    src: StrideS,
};

const StrideLogo = () => {
    return (
        <>
            <Logo />
            <Heading>STRIDE</Heading>
        </>
    )
}

export default StrideLogo;