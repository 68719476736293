import {CenteredRow, Row, XSpacer} from "../../../../StyledComponents";
import StrideLogo from "../generic/StrideLogo";
import LocalizedStrings from "react-localization";
import {data} from "../../../../../assets/strings";
import styled from "styled-components"

import ScrollMenuItem from "./ScrollMenuItem";
import MenuItem from "./MenuItem";
import theme from "../../../../Theme";
import {useState} from "react";

let strings = new LocalizedStrings(data);
strings.setLanguage('en');

const NavigationBar = styled(Row)`
    background-color: ${theme.colors.grey};
    border-bottom: 1px solid ${theme.colors.napa};
    position: sticky;
    top: 0;
    z-index: 4;
`


const DesktopMenuBar = (props) => {

    const pathname = window.location.pathname
    const hash = window.location.hash
    const [selectedPage, setSelectedPage] = useState(pathname + hash)

    return (
        <NavigationBar>
            <CenteredRow justifyContent={'left'}>
                <XSpacer spaceX={'3vw'} />
                <StrideLogo />
            </CenteredRow>
            <CenteredRow justifyContent={'right'} marginLeft={'auto'}>
                <MenuItem path={'/'}
                          text={'Home'}
                          selectedPage={selectedPage}
                          onSelectedPageChanged={setSelectedPage}/>
                <XSpacer spaceX={'3vw'} />

                <ScrollMenuItem path={"/#demo"}
                                text={strings.demo}
                                selectedPage={selectedPage}
                                onSelectedPageChanged={setSelectedPage}/>
                <XSpacer spaceX={'3vw'} />

                <ScrollMenuItem path={"/#contact-us"}
                                text={strings.contactUs}
                                selectedPage={selectedPage}
                                onSelectedPageChanged={setSelectedPage}/>
                <XSpacer spaceX={'3vw'} />

                {/*<MenuItem path={'/payment'}*/}
                {/*          text={'Payment'}*/}
                {/*          selectedPage={selectedPage}*/}
                {/*          onSelectedPageChanged={setSelectedPage}/>*/}
                {/*<XSpacer spaceX={'3vw'} />*/}

                {/*<MenuItem path={'/signup'}*/}
                {/*          text={'Sign Up'}*/}
                {/*          selectedPage={selectedPage}*/}
                {/*          onSelectedPageChanged={setSelectedPage}/>*/}

                {/*<HamburgerMenu />*/}
                {/*<XSpacer spaceX={'3vw'} />*/}
            </CenteredRow>
        </NavigationBar>
    )
}

export default DesktopMenuBar;