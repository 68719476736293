import styled from "styled-components";
import theme from "../../../../Theme";
import {Box, CenterAlignedRow} from "../../../../StyledComponents";


const List = styled.ul`
    list-style-type: circle;
`

const Item = styled.li`
  font-family: Cooper Hewitt, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.fontSizes.small};
  color: ${props => props.color};
  margin-bottom: .1vw;
`

const BulletList = (props) => {

    return (
        <CenterAlignedRow {...props}>
            <List>
                {props.items.map(function(text) {
                    return (
                        <>
                            <Item color={props.textColor}>{text}</Item>;

                        </>
                    )
                })}
            </List>
        </CenterAlignedRow>
    )

}

export default BulletList;