import {CenterAlignedRow, CenteredRow, Row, XSpacer, YSpacer} from "../../../../StyledComponents";
import StrideLogo from "../generic/StrideLogo";
import LocalizedStrings from "react-localization";
import {data} from "../../../../../assets/strings";
import HamburgerMenu from "../generic/HamburgerMenu";
import theme from "../../../../Theme";

const MobileMenuBar = () => {
    return (
        <CenteredRow background={theme.colors.grey} height={'100px'} justifyContent={'center'}>
            <StrideLogo />
            {/*<XSpacer spaceX={'2em'} />*/}
            {/*<HamburgerMenu />*/}
        </CenteredRow>
    )
}

export default MobileMenuBar;