import styled from "styled-components";
import theme from "./Theme";

export const Heading = styled.h1`
  font-family: Cooper Hewitt, sans-serif;
  font-style: italic;
  font-weight: 800;
  font-size: ${theme.fontSizes.xl};
  text-align: center;
  color: #000000;
  margin: 0px;
`;

export const MediumHeading = styled.h3`
  min-width: fit-content;
  font-family: Cooper Hewitt, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: ${theme.fontSizes.medium};

  color: ${props => props.color};
`;

export const SubHeading = styled.h2`
  font-family: din-2014, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: ${theme.fontSizes.large};
  width: 80vw;
  align-items: center;
  text-align: center;

  color: #B0AA99;
`;


export const BodyText = styled.p`
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
  font-family: Cooper Hewitt, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.fontSizes.small};
  color: ${props => props.color};
  text-align: center;
  white-space: pre-line;
`;

export const BoldedBodyText = styled.p`
  font-family: Cooper Hewitt, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: ${theme.fontSizes.small};
  color: ${props => props.color};
`;

export const MenuItemText = styled.h3`
  min-width: fit-content;
  font-family: Cooper Hewitt, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: ${theme.fontSizes.medium};
  color: ${props => props.color};
  
  cursor: pointer;
  &:hover {
    color: ${theme.colors.black};
  }
`;