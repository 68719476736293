import { Link } from "react-router-dom";
import {MenuItemText} from "../../../../Texts";
import theme from "../../../../Theme";

const MenuItem = (props) => {

    function handleClick() {
        document.querySelector("body").scrollTo(0,0)
        props.onSelectedPageChanged(props.path)
    }

    let color = theme.colors.napa
    if (props.selectedPage === props.path) {
        color = theme.colors.black;
    }
    return (
        <Link to={props.path} style={{ textDecoration: 'none' }} onClick={handleClick}>
            <MenuItemText color={color}>{props.text}</MenuItemText>
        </Link>
    )
}

export default MenuItem;
