
const colors = {
    grey_transparent: '#C2BEB34D',
    grey: '#EDECE8',
    orange: '#FF3E00',
    black: '#000000',
    white: '#FFFFFF',
    dark_grey: "#495057",
    border_light_grey: '#ced4da',
    napa: '#B0AA99'
};

const fontWeights = {
    thin: 100,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 900,
};

const fontSizes = {
    root1920: '21.3333px',
    root1440: '16px',
    root1120: '12.4444px',

    micro: '0.84375em',
    small: '1.125em',
    medium: '1.6875em',
    large: '3.375em',
    xl: '4.5em',
}

const fixedWidths = {
    desktop: '25rem',
    mobile: '20rem',
}

const widthQueries = {
    minWidthQuery: "796px",
    maxWidthQuery: "795px"
}

const breakpoints = [766, 1118, 1200];

const theme = {
    colors,
    //     [0, 1, 2, 3, 4, 5,  6,  7,  8,  9,  10, 11, 12, 13, 14, 15, 16, 17,  18,  19,  20,  21 ]
    space: [0, 2, 4, 6, 8, 10, 12, 16, 20, 24, 28, 32, 40, 48, 56, 64, 76, 128, 192, 256, 384, 512],
    //     [0, 1, 2, 3, 4, 5,  6,  7,  8,  9,  10, 11, 12, 13, 14, 15, 16, 17,  18,  19,  20,  21 ]
    sizes: [0, 2, 4, 6, 8, 10, 12, 16, 20, 24, 28, 32, 40, 48, 56, 64, 76, 128, 192, 256, 384, 512],
    fontWeights,
    fontSizes,
    fixedWidths,
    widthQueries,
    borders: {},
    breakpoints,
};

export default theme;
