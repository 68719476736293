import {Box, CenterAlignedRow, CenteredCol, Col, XSpacer, YSpacer} from "../../../../StyledComponents";
import {BodyText, BoldedBodyText, FixedWidthBodyText, MediumHeading} from "../../../../Texts";
import LocalizedStrings from "react-localization";
import {data} from "../../../../../assets/strings";
import BulletList from "../generic/BulletList";
import theme from "../../../../Theme";

let strings = new LocalizedStrings(data);
strings.setLanguage('en');

const DesktopProblemSolution = () => {
    return (
        <Box backgroundColor={'#000000'} maxHeight={'326px'}>
            <YSpacer spaceY={'5vw'} maxHeight={'60px'}/>
            <CenterAlignedRow>
                <XSpacer spaceX={'10vw'} />
                <CenteredCol lineHeight={'30px'}>
                    <MediumHeading color={'#ffffff'}>{strings.problemTitle}</MediumHeading>
                    <BodyText color={'#ffffff'}>
                        {"Forgotten Exercise Papers\n" +
                            "Lack of Accountability\n" +
                            "Poor Patient Outcomes"}
                    </BodyText>
                    {/*<BulletList width={theme.fixedWidths.desktopMedium} items={strings.problemBulletList} textColor={'#ffffff'} />*/}
                </CenteredCol>

                <XSpacer spaceX={'20vw'} maxWidth={'350px'} />

                <CenteredCol lineHeight={'30px'}>
                    <MediumHeading color={'#ffffff'}>{strings.solutionTitle}</MediumHeading>
                    <BodyText color={'#ffffff'}>
                        {"Digital Home Exercise Plan\n" +
                            "Notifications for Patients\n" +
                            "Improve Adherence by 50%"}
                    </BodyText>
                    {/*<BulletList width={theme.fixedWidths.desktopMedium} items={strings.solutionBulletList} textColor={'#ffffff'} />*/}
                </CenteredCol>
                <XSpacer spaceX={'10vw'} />
            </CenterAlignedRow>
            <YSpacer spaceY={'7vw'} />
        </Box>
    )
}

export default DesktopProblemSolution;