import styled from "styled-components";
import theme from "./Theme";

export const OrangeButton = styled.button`
  height: 3.2813rem;
  width: ${props => props.width};
  max-height: 52.5px;
  cursor: pointer;
  background: #FF3E00;
  border-radius: 36px;
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  font-family: Cooper Hewitt, sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: ${theme.fontSizes.medium};
  padding-right: clamp(10px, 1vw, 20px);
  padding-left: clamp(10px, 1vw, 20px);
  color: #FFFFFF;
`;